<template>
추천 보너스
</template>

<script>
export default {
  name:"EventRecommendBonus"
}
</script>

<style scoped>

</style>
